import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import ImageLogo from 'public/logo.svg';
import Phone from 'public/phone.svg';
import Facebook from 'public/socialIcons/facebook.svg';
import Instagram from 'public/socialIcons/instagram.svg';
import LinkedIn from 'public/socialIcons/linked-in.svg';
import BankMisr from 'public/payment/Banque-Misr.jpeg';
import Cib from 'public/payment/cib.jpeg';
import Mashriq from 'public/payment/mashreq.jpeg';
import Nbe from 'public/payment/NBE.jpeg';
import Sympl from 'public/payment/sympl.jpeg';
import Valu from 'public/payment/valu.png';
import Shoola from 'public/payment/souhoola.png';
import Forsa from 'public/payment/forsa.png';
import PremiumCard from 'public/payment/premium-card.png';
import React from 'react';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import {
  Copy,
  Div,
  FooterContent,
  FooterLink,
  HotLine,
  LogoLink,
  SocialIcon,
  GroupLogos,
  SocialIconsList,
  Title,
  Wrapper,
} from './Footer.styled';
import { useRouter } from 'next/router';
type Props = {};

export default React.memo(function Footer({}: Props) {
  const { t } = useTranslation('common');
  const { query } = useRouter();
  const hasThirdpartyParam = query.hasOwnProperty('thirdparty');
  const showFooter = !hasThirdpartyParam;

  if (showFooter) {
    return (
      <Wrapper>
        <Container>
          <FooterContent>
            <Row>
              <Col xl={4} lg={6} md={12}>
                <LogoLink href="/">
                  <Image src={ImageLogo} width={242.63} height={34} alt="YourParts" />
                </LogoLink>
                <SocialIconsList>
                  <SocialIcon target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/YourPartsEG/">
                    <Image src={Facebook} width={12.53} height={24} alt="Facebook" />
                  </SocialIcon>
                  <SocialIcon
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://instagram.com/yourpartseg?utm_medium=copy_link"
                  >
                    <Image src={Instagram} width={22.09} height={22.09} alt="Instagram" />
                  </SocialIcon>
                  <SocialIcon
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.linkedin.com/company/your-parts"
                  >
                    <Image src={LinkedIn} width={19.77} height={19.77} alt="Linkedin" />
                  </SocialIcon>
                </SocialIconsList>
                {/* <Form>
                <Label>{t('subscribe')}</Label>
                <InputWrapper>
                  <Input placeholder={t('subscribe_input')} type="email" />
                  <Button>
                    <Image src={Send} width={24} height={18.96} alt="Send" />
                  </Button>
                </InputWrapper>
              </Form> */}
              </Col>
              <Col xl={2} lg={6} md={6} sm={6} xs={6}>
                <Title>{t('solutions')}</Title>
                <FooterLink href="/return-policy">{t('return-policy')}</FooterLink>
                <FooterLink href="/products">{t('our-products')}</FooterLink>
                {/* <FooterLink href="/profile/faq">{t('faq')}</FooterLink> */}
                {/* <FooterLink href="/profile/hotline">{t('contact_us')}</FooterLink> */}
              </Col>
              <Col xl={2} lg={6} md={6} sm={6} xs={6}>
                <Title>{t('more')}</Title>
                <FooterLink href="/about">{t('about')}</FooterLink>
                <FooterLink href="/contact">{t('contact_us')}</FooterLink>
                <FooterLink href="/terms">{t('terms-conditions')}</FooterLink>
                <FooterLink href="/privacy-policy">{t('privacy')}</FooterLink>
                {/* <FooterLink href="#">{t('payment')}</FooterLink> */}
                {/* <FooterLink href="#">{t('service')}</FooterLink> */}
                {/* <FooterLink href="#">{t('guarantee')}</FooterLink> */}
              </Col>
              <Col xl={4} lg={6} md={12}>
                <Div>
                  <HotLine>
                    <Image src={Phone} width={34} height={34} alt="Phone" />
                    <a href="tel:15145" target="_blank" rel="noreferrer noopener" className="hotline-num">
                      15145 <span>{t('hotline')}</span>
                    </a>
                  </HotLine>
                  {/* <DownloadApp>
                  <Overline>{t('download')}</Overline>
                  <Store target="_blank" href="https://migggz.me" rel="noreferrer noopener">
                    <Image src={PlayStore} width={151.52} height={43.21} alt="Play Store" />
                  </Store>
                  <Store target="_blank" href="https://migggz.me" rel="noreferrer noopener">
                    <Image src={AppStore} width={151.52} height={43.21} alt="App Store" />
                  </Store>
                </DownloadApp> */}
                </Div>
              </Col>
            </Row>
          </FooterContent>
          <Copy>
            &copy;{new Date().getFullYear()} {t('yourparts')}, {t('rights-reserved')}.
            <GroupLogos>
              <Image src={Valu} width={'100%'} height={32} alt="Valu" />
              <Image src={BankMisr} width={'100%'} height={32} alt="BankMisr" />
              <Image src={Cib} width={'100%'} height={32} alt="Cib" />
              <Image src={Nbe} width={'100%'} height={32} alt="Nbe" />
              <Image src={Mashriq} width={'100%'} height={32} alt="Mashriq" />
              <Image src={Sympl} width={'100%'} height={32} alt="Sympl" />
              <Image src={Shoola} width={'100%'} height={32} alt="Shoola" />
              <Image src={Forsa} width={'100%'} height={32} alt="Forsa" />
              <Image src={PremiumCard} width={'100%'} height={32} alt="PremiumCard" />
            </GroupLogos>
            {/* <Privacy>
            <PrivacyLink href="/privacy-policy">{t('privacy')}</PrivacyLink>
            <PrivacyLink href="/terms">{t('terms')}</PrivacyLink>
          </Privacy> */}
          </Copy>
        </Container>
      </Wrapper>
    );
  }

  return <></>;
});
